import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { Card } from 'react-bootstrap'

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { fillTemplate, numRoundoff, hyphenatedName } from "../../services/format"
import { InfluencerT } from "../../services/seotemplate"

import Reco from "../../components/reco"
import InfluencerBadge from '../../components/influencerbadge'
import FooterBtn from "../../components/ui/footerbtn"

import "../../styles/global.css"

const Books = ({ data }) => {
  const { edges:recos } = data.allReco
  const influencer = data.allInfluencer.edges[0].node;
  let image = influencer.profile_image
  const templateVars = {}
  
  templateVars.num_of_books = numRoundoff(data.allReco.totalCount)
  templateVars.influencer = (influencer.display_name !== null) ? 
  influencer.display_name : influencer.user_name
  
  const title = fillTemplate(InfluencerT.title, templateVars)
  const description = fillTemplate(InfluencerT.description, templateVars)
  const heading = fillTemplate(InfluencerT.heading, templateVars)

  const schema = 
  {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": title,
    "description": description
  }

  const canonicalUrl = 'https://bookschatter.com/books/' + hyphenatedName(templateVars.influencer)

  if (image !== null && image !== undefined)
    image = image.replace("_normal","")

  return(
      <Layout>
          <SEO title={title} description={description} image={image} schemaMarkup={schema}/>
          <Helmet link={[{ rel: 'canonical', href: canonicalUrl, 'data-baseprotocol': "https:", 'data-basehost': "www.bookschatter.com"  }]}/>
          <InfluencerBadge influencer={influencer}/>'
          <h3 style={{ fontSize: '2em'}}>{heading}</h3>
          <ul style={{ padding: 0 }}>
              {recos.map(reco => { 
                const { id, tweet_id, bookObjects } = reco.node
                const hyphen_title = hyphenatedName(bookObjects[0].title)

                return(
                  <li
                  key={id}
                  style={{
                      textAlign: "center",
                      listStyle: "none",
                      display: "block"
                  }}
                  >
                      <Card id={ tweet_id + '_' + hyphen_title} style = {{marginBottom: "30px"}}> 
                          <Card.Body>
                              <Reco reco={reco.node} />  
                          </Card.Body> 
                          {bookObjects[0].categoryMapping ? 
                          <Card.Footer>
                            <FooterBtn path={"/best-books/" + bookObjects[0].categoryMapping.urlName} label={`More in ${bookObjects[0].categoryMapping.displayName}`} />
                          </Card.Footer>
                          : null}
                      </Card>
                  </li>
                )
              })}
          </ul>
      </Layout>
  )
}

export const query = graphql`
  query($name: String!) 
  {
    allReco(filter: {user_screen_name: {eq: $name}}) {
      totalCount
    }
    allReco(filter: {user_screen_name: {eq: $name}}) {
      edges {
        node {
          id  
          tweet_id
          text
          created_at
          ...InfluencerFragment
          ...BookFragment
        }
      }
    }
    allInfluencer(filter: {user_screen_name: {eq: $name}}) {
        edges {
          node {
            profile_image
            num_of_books
            display_name
            description
            user_name
            user_screen_name
          }
        }
    }
  }
`

export default Books
import React from 'react'
import { Row,Col,Image } from 'react-bootstrap'
import { Follow } from 'react-twitter-widgets'
import './style.css'

import { getInfluencerDetails } from '../../services/influencerdetails'

function InfluencerBadge({influencer}){
    const { name, screen_name, profile_image, description } = getInfluencerDetails(influencer)

    return(
        <Row className="user-div">
            <Col xs={4} sm={4} lg={2}>
                <Image className="photo-small" src={profile_image} alt={name} roundedCircle />
            </Col>
            <Col className="user">
                <Row>
                    <h1>{name}</h1>
                </Row>
                <Row>
                    <h3><a target="_blank" rel="noreferrer" href= {"https://twitter.com/" + screen_name}>@{screen_name}</a></h3>
                </Row>
                <Row>{description ? <p style={{marginBottom:"1rem"}}>{description}</p>: null}</Row>
                <Row>
                    <Follow username={screen_name}/>
                </Row>
            </Col>
        </Row>
    )
}

export default InfluencerBadge;
